import { useCallback, useEffect, useRef, useState } from "react";
import { getRankedStats } from "../../api/ranked-stats/ranked-stats";
import { ALL_ID, CurrentEvent, Event, RankedStatsId } from "../../api/ranked-stats/ranked-stats.types";
import { RankedsTable } from "./rankeds-table/rankeds-table";
import { RankedsMaps } from "./rankeds-maps/rankeds-maps";
import { MainContainer, MainContainerMb, MainTitle, MapsContainer, MapsContainerMb, PageContainer, TableContainer } from "./rankeds.styles";
import { Box, useMediaQuery } from "@mui/material";
import { Footer } from "../../components/footer/footer";
import { screenBreackpoints } from "../../theme";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SeoHelmet } from "../../components/seo-helmet/seo-helmet";

export const Rankeds: React.FC = () => {
  const [searchParams] = useSearchParams();
  const initEventId: RankedStatsId = searchParams.get('eventId') && /^[0-9]+$/.test(searchParams.get('eventId')!)
    ? searchParams.get('eventId') as RankedStatsId : ALL_ID;
  const [eventId, setEventId] = useState<RankedStatsId>(initEventId);
  const [currentEvents, setCurrentEvents] = useState<CurrentEvent[]>([]);
  const [event, setEvent] = useState<Event>();
  const isMobile = useMediaQuery(`(max-width:${screenBreackpoints.mobile})`);
  const { t } = useTranslation();

  const pageContainerRef = useRef<HTMLDivElement | null>(null);

  const handleNavigation = useCallback((eventId: RankedStatsId) => {
    setEventId(eventId);
    pageContainerRef.current!.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const loadRankedStats = async () => {
      try {
        const rankedStats = await getRankedStats(eventId);
        if (eventId !== ALL_ID && !rankedStats.event) { handleNavigation(ALL_ID) }
        setEvent(rankedStats.event);
        setCurrentEvents(rankedStats.currentEvents);
        //const eventIds = rankedStats.currentEvents.map(event => event.id)
        //window.console.log(eventIds)
      } catch (error) {
        window.console.log(error)
      }
    };
    loadRankedStats()
  }, [eventId, handleNavigation]);

  const title = (event?.id === null ? "Topbrawl - " : "")
    + t("RANKEDS_PAGE.TABLE.BEST_BRAWLERS")
    + (event?.id === null ? t("RANKEDS_PAGE.TABLE.ALL_MAPS") : t(`DOMINE.MODE.${event?.mode}`))
    + (event?.id === null ? "" : `${t("RANKEDS_PAGE.TABLE.BEST_BRAWLERS_AT")}${event?.name}`);
  const description = t("RANKEDS_PAGE.SEO.DESCRIPTION")
    + (event?.id === null ? t("RANKEDS_PAGE.TABLE.ALL_MAPS") : t(`DOMINE.MODE.${event?.mode}`))
    + (event?.id === null ? "" : `${t("RANKEDS_PAGE.TABLE.BEST_BRAWLERS_AT")}${event?.name}`);
  const keywords = t("RANKEDS_PAGE.SEO.KEYWORDS")
    + `${event?.mode ? (", " + t(`DOMINE.MODE.${event?.mode}`)) : ""}`
    + `${event?.id ? (", " + event?.name) : ""}`;

  return (
    <>
      <SeoHelmet title={title} description={description} keywords={keywords} />
      <PageContainer isMobile={isMobile} ref={pageContainerRef}>
        <Box>
          <MainTitle component={event?.id === null ? "h1" : "div"} isMobile={isMobile}>
            {t("RANKEDS_PAGE.TITLE")} <br />
            {t("RANKEDS_PAGE.SUBTITLE")}
          </MainTitle>
          {isMobile ?
            <MainContainerMb>
              {event && (
                <RankedsTable event={event} />
              )}
              {currentEvents && (
                <MapsContainerMb>
                  <RankedsMaps
                    selectedMapId={eventId}
                    currentEvents={currentEvents}
                    onClickMap={(eventId) => handleNavigation(eventId)} />
                </MapsContainerMb>
              )
              }
            </MainContainerMb>
            :
            <MainContainer>
              <TableContainer>
                {event && (
                  <RankedsTable event={event} />
                )}
              </TableContainer>
              <MapsContainer>
                {currentEvents && (
                  <RankedsMaps
                    selectedMapId={eventId}
                    currentEvents={currentEvents}
                    onClickMap={(eventId) => handleNavigation(eventId)} />
                )}
              </MapsContainer>
            </MainContainer>
          }
        </Box>
        <Footer />
      </PageContainer>
    </>
  );
}
