import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { componentColors, componentStyles } from '../../../theme';

export const AllMapsContainer = styled(Box)`
  padding-bottom: 16px;
`;

export const AllMapsTextContainer = styled(Box)`
  padding: 16px;
`;

export const ModeTitleContainer = styled(Box)`
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
`;

interface ModeTitleProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  component?: string;
  modeColor: string
}

export const ModeTitle = styled(Typography) <ModeTitleProps>`
  height: 25px;
  font-size: 1.2em;
  padding-left: 8px;
  border-bottom: 3px solid ${({ modeColor }) => modeColor};
`;

interface ModeImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  component?: string;
  modeColor: string
}

export const ModeImage = styled(Typography) <ModeImageProps>`
  height: 24px;
  width: 24px;
  padding: 2px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: ${({ modeColor }) => modeColor};
`;

export const ModeContainer = styled(Box)`
  display: grid;
  gap: 8px;
  padding-bottom: 24px;
  justify-items: center;

  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;

interface MapContainerBtnProps {
  isSelected: boolean;
}

export const MapContainerBtn = styled(Box) <MapContainerBtnProps>`
  background-color: ${componentColors.card};
  border: 1px solid ${componentColors.cardBorder};
  border-radius: ${componentStyles.cardRadius};
  transition: transform 0.1s ease;
  overflow: hidden;
  margin-bottom: 8px;

  &:hover {
    border-color: ${componentColors.selectedItem};
    box-shadow: 0px 0px 8px ${componentColors.selectedItem}60;
    transform: scale(1.03);
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    border-color: ${componentColors.selectedItem};
    `
  }
`;

export const MapContainer = styled(MapContainerBtn) <MapContainerBtnProps>`
  max-width: 200px;
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${componentColors.cardSelected};
    `
  }
`;

export const ImageMapContainer = styled(Box) <React.ImgHTMLAttributes<HTMLImageElement>>`
 width: 100%;
 height: auto;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 2px 8px;
  background-color: ${componentColors.bar};
`;
